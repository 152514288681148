import React, { useEffect, useState } from "react";
import Header from "../Header";
import axios from "axios";
import { url } from "../../config/config";
import { isLoggedIn } from "../../utils";
import InVoice from "./InVoice";
import { FaDownload } from "react-icons/fa6";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
  },
  header: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 20,
    // backgroundColor:"FD701E",
    color: "#FD701E",
  },
  section: {
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "600",
    color: "#FD701E",
    fontSize: "20px",
  },
  table: {
    display: "table",
    width: "auto",
    marginTop: 20,
    marginBottom: 20,
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "33.33%",
    borderStyle: "solid",
    borderColor: "#FD701E",
    backgroundColor: "#FD701E",
    color: "#fff",
    borderBottomColor: "#000",
    borderWidth: 1,
    textAlign: "center",
    padding: 5,
    fontWeight:"600"
  },
  tableCol: {
    width: "33.33%",
    borderStyle: "solid",
    borderColor: "#FD701E",
    backgroundColor: "#fff",
    borderWidth: 1,
    textAlign: "center",
    padding: 5,
    color:"#000"
  },
  footer: {
    textAlign: "center",
    marginTop: 20,
  },
});

const InvoiceDocument = ({ plan , userDetails }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={{ fontWeight: "bold" }}>Subscription Invoice</Text>
        <Text>
          Invoice No: #INV123456 | Date: {new Date().toLocaleDateString()}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.boldText}>Billing Details</Text>
        <Text>From: PGDEKHOINDIA</Text>
        <Text>
          Roomer Technologies Pvt. Ltd. Plot No.4, Neelkanth Enclave, Sector 69,
          Gurugram, (Haryana) -122101 India pgdekho69@gmail.com
        </Text>
        <Text>GSTIN: 22AAAAA0000A1Z5</Text>
      </View>

      <View style={styles.section}>
        <Text>
          To: {userDetails?.name}</Text>
        <Text>{userDetails?.phone} </Text>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableColHeader}>Plan Name</Text>
          <Text style={styles.tableColHeader}>Subscription Period</Text>
          <Text style={styles.tableColHeader}>Price</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCol}>{plan?.planDetails?.[0]?.basicInfo?.planName}</Text>
          <Text style={styles.tableCol}>{plan?.startDate?.split("T")[0]} - 15/12/26</Text>
          <Text style={styles.tableCol}>{plan?.subscriptionType == "monthly"
                            ? plan?.planDetails?.[0]?.pricingInformation
                                ?.AnnualDiscountedRate
                            : plan?.planDetails?.[0]?.pricingInformation
                                ?.monthlyRate}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.boldText}>Total:{plan?.subscriptionType == "monthly"
                            ? plan?.planDetails?.[0]?.pricingInformation
                                ?.AnnualDiscountedRate
                            : plan?.planDetails?.[0]?.pricingInformation
                                ?.monthlyRate}</Text>
        {/* <Text>Amount in words: Five Hundred Rupees Only</Text> */}
      </View>

      <View style={styles.footer}>
        <Text>Thank you for your purchase!</Text>
        <Text>
          If you have any questions, contact us at support@company.com or call
          +91-1234567890
        </Text>
      </View>
    </Page>
  </Document>
);

const SubscriptionList = () => {
  const [list, setList] = useState([]);
  const userDetails = JSON.parse(window.localStorage.getItem("pgUser"));
  const fetchSubscriptionList = async () => {
    const response = await axios.get(
      `${url}user/getAllSubscription?userID=${userDetails?._id}`,
      {
        headers: {
          Authorization: isLoggedIn("pgUser"),
        },
        // params: payload
      }
    );
    setList(response?.data?.data?.subscriptions);
  };
  useEffect(() => {
    fetchSubscriptionList();
  }, []);

  console.log(list,"FFFFFFFFFFFFFFFFFF")

  return (
    <>
      <Header />
      <section>
        <div className="subsciptions-area">
          <div className="subsciptions">
            <h3>My Subscription</h3>
            <div className="TableList">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Transaction ID</th>
                    <th>Plan Name</th>
                    <th>Subscription Type</th>
                    <th>Purchased on</th>
                    <th>Payment Amount</th>
                    <th>Valid Till</th>
                    <th>Status</th>
                    <th>Download Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {list?.map((elem, id) => {
                    return (
                      <tr>
                        <td>{elem?.subscriptionId}</td>
                        <td>{elem?.planDetails?.[0]?.basicInfo?.planName}</td>
                        <td>
                          {elem?.subscriptionType == "monthly"
                            ? "Monthly"
                            : "Yearly"}
                        </td>
                        <td>{elem.startDate?.split("T")[0]}</td>
                        <td>
                          Rs.{" "}
                          {elem?.subscriptionType == "monthly"
                            ? elem?.planDetails?.[0]?.pricingInformation
                                ?.AnnualDiscountedRate
                            : elem?.planDetails?.[0]?.pricingInformation
                                ?.monthlyRate}
                        </td>
                        <td>15-12-2024</td>
                        <td>{elem?.status}</td>
                        <td>
                          {/* <div className="Actions">
                            <img src="images/pdf.png" alt="" />
                          </div> */}
                          <PDFDownloadLink
                            document={<InvoiceDocument plan={elem} userDetails={userDetails}/>}
                            fileName="invoice.pdf"
                            style={{ color: "#000" }}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? (
                                "Loading document..."
                              ) : (
                                <FaDownload style={{ fontSize: "22px" }} />
                              )
                            }
                          </PDFDownloadLink>
                          {/* <PDFDownloadLink document={<InVoice plan={elem} />} fileName='invoice.pdf'>
       <div >
          <HiOutlineDownload size={14}/>
          <span>Download</span>
       </div>
     </PDFDownloadLink> */}
                        </td>
                      </tr>
                    );
                  })}

                  {/* <tr>
            <td>32597579509500</td>
            <td>Gold Plan</td>
            <td>12-03-2024</td>
            <td>Rs. 5000</td>
            <td>15-02-2024</td>
            <td>
              <div className="Actions">
                <img src="images/pdf.png" alt="" />
              </div>
            </td>
          </tr>
          <tr>
            <td>32597579509500</td>
            <td>Gold Plan</td>
            <td>12-03-2024</td>
            <td>Rs. 5000</td>
            <td>15-02-2024</td>
            <td>
              <div className="Actions">
                <img src="images/pdf.png" alt="" />
              </div>
            </td>
          </tr>
          <tr>
            <td>32597579509500</td>
            <td>Gold Plan</td>
            <td>12-03-2024</td>
            <td>Rs. 5000</td>
            <td>15-02-2024</td>
            <td>
              <div className="Actions">
                <img src="images/pdf.png" alt="" />
              </div>
            </td>
          </tr>
          <tr>
            <td>32597579509500</td>
            <td>Gold Plan</td>
            <td>12-03-2024</td>
            <td>Rs. 5000</td>
            <td>15-02-2024</td>
            <td>
              <div className="Actions">
                <img src="images/pdf.png" alt="" />
              </div>
            </td>
          </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubscriptionList;
