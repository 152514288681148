import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  verified: true,
  name: "",
  lat: "",
  long: "",
  gender: "",
  roomType: "",
  food: "",
  postBy: "",
  parking: "",
  sort: "",

  city: "",
  locality: "",
  street: "",
  address: "",
  availability: "",
  roomType: [],
  location: {
    type: "point",
    coordinates: [],
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchState: (state, action) => {
      return { ...state, ...action.payload };
    }
  }
});

export const { setSearchState } = searchSlice.actions;
export default searchSlice.reducer;