
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';
import {url} from "../../../config/config"


export const fetchContactusContent = createAsyncThunk('AboutusContent/fetchAboutus', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`${url}user/websiteContactUsContent`, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;
});

const initialState = {
    contactusData: {},
    status: 'idle',
    error: null,
}
const contactusSlice = createSlice({
    name: 'conatctusData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchContactusContent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchContactusContent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.contactusData = action.payload;
            })
            .addCase(fetchContactusContent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default contactusSlice.reducer;
