import { Routes, Route } from "react-router-dom";

import "./App.css";
import Home from "./component/Home";
import Login from "./component/Auth/Login";
import LoginVerify from "./component/Auth/LoginVerify";
import CreateAccount from "./component/Auth/CreateAccount";
import Congratulation from "./component/Auth/Congratulation";
import SearchResult from "./component/SearchResult";
import PgDetails from "./component/PgDetails";
import FlatSearchResult from "./component/FlatSearchResult";
import Sell from "./component/Sell";
import MyProfile from "./component/Auth/MyProfile";
import Faq from "./component/Faq";
import PrivateRoute from "./component/Auth/PrivateRoute";


import EditProperty1 from "./component/EditProperty1";
import EditProperty2 from "./component/EditProperty2";
import EditProperty3 from "./component/EditProperty3";
import EditProperty4 from "./component/EditProperty4";
import EditProperty5 from "./component/EditProperty5";
import EditProperty6 from "./component/EditProperty6";

import FlatProperty1 from "./component/FlatProperty1";
import FlatProperty2 from "./component/FlatProperty2";
import FlatProperty3 from "./component/FlatProperty3";
import FlatProperty4 from "./component/FlatProperty4";
import FlatProperty5 from "./component/FlatProperty5";
import FlatProperty6 from "./component/flatProperty6";



import About from "./component/Aboutus";
import ContactUs from "./component/Contactus";
import TermCondition from "./component/TermsCondition"
import PrivacyPolicy from "./component/PrivacyPolicy"
import EditProfile from "./component/Auth/EditProfilex";
import PropertyListing from "./component/User/PropertyListing";
import WishList from "./component/User/WishList";
import FlatDetails from "./component/FlatDetails";
import ActivityContact from "./component/User/ActivityContact";
import PropertyResponses from "./component/User/PropertyResponses";
import HelpAndSupport from "./component/helpAndSupport/HelpAndSupport";
import MyTicketSupport from "./component/helpAndSupport/MyTicketSupport";
import SubscriptionList from "./component/User/SubscriptionList";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCityState } from "./reduxToolKit/slices/citySlice";
import { fetchHomelisting } from "./reduxToolKit/slices/homeListingSlice";



function App() {


const dispatch = useDispatch()



  const fetchAddress = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB0oVP9ASJypwf7BLW86zTwqqM2iSI0DuE`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data, "data")
        let tempFormattedAddress = data?.results?.filter((elem)=>elem.types.includes("locality")) ?? "";
        console.log(tempFormattedAddress, "data")
        dispatch(fetchHomelisting({type:'PG',city:tempFormattedAddress?.[0]?.formatted_address?.split(",")?.[0]}))
            dispatch(setCityState({currentLocation:tempFormattedAddress?.[0]?.formatted_address}
            ))
      });
  };

 

  const autoLocationHandler = () => {


    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      if (permissionStatus.state === 'denied') {
        console.log('Geolocation permission is denied.');

        dispatch(fetchHomelisting({type:'PG',city:'Gurugram'}))

       dispatch(setCityState({ currentLocation: 'Gurugram' }))
      } else if (permissionStatus.state === 'granted') {
        navigator.geolocation.getCurrentPosition((position, error) => {
          if (position) {
            fetchAddress(position?.coords?.latitude, position?.coords?.longitude);

          }
        });
      } else {
        console.log('Geolocation permission is in prompt state.');
      }
    })
   
  };

  useEffect(() => {
    autoLocationHandler();
  }, []);


  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/login" element={<Login />} />
      <Route path="/my-profile" element={<MyProfile />} />   
      <Route path="/edit-profile" element={<EditProfile />} />



      <Route path="/login-verify" element={<LoginVerify />} />
      <Route path="/property-listing" element={<PropertyListing />} />
      <Route path="/wish-list" element={<WishList />} />
      <Route path="/subscription-list" element={<SubscriptionList />} />
      <Route path="/acitvity-contact" element={<ActivityContact />} />
      <Route path="/property-responses" element={<PropertyResponses />} />

      




      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/congratulation" element={<Congratulation />} />
      <Route path="/search-result" element={<SearchResult />} />

      <Route path="/pg-details" element={<PgDetails />} />
      <Route path="/flat-details" element={<FlatDetails />} />

      <Route path="/sell" element={<Sell />} />

{/* dsds */}
      <Route path="/flat-search-result" element={<FlatSearchResult />} />

      <Route path="/edit-property-1" element={<PrivateRoute component={EditProperty1} />} />
      <Route path="/edit-property-2" element={<PrivateRoute component={EditProperty2} />} />
      <Route path="/edit-property-3" element={<PrivateRoute component={EditProperty3} />} />
      <Route path="/edit-property-4" element={<PrivateRoute component={EditProperty4} />} />
      <Route path="/edit-property-5" element={<PrivateRoute component={EditProperty5} />} />
      <Route path="/edit-property-6" element={<PrivateRoute component={EditProperty6} />} />

      <Route path="/flat-property1" element={<PrivateRoute component={FlatProperty1} />} />
      <Route path="/flat-property2" element={<PrivateRoute component={FlatProperty2} />} />
      <Route path="/flat-property3" element={<PrivateRoute component={FlatProperty3} />} />
      <Route path="/flat-property4" element={<PrivateRoute component={FlatProperty4} />} />
      <Route path="/flat-property5" element={<PrivateRoute component={FlatProperty5} />} />
      <Route path="/flat-property6" element={<PrivateRoute component={FlatProperty6} />} />


      <Route path="/about-us" element={<About />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/terms-condition" element={<TermCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/faq" element={<Faq />} />


      <Route path="/help-support" element={<HelpAndSupport />} />
      <Route path="/my-ticket-support" element={<MyTicketSupport />} />





      
 

   
    </Routes>
  );
}

export default App;
