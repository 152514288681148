
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../utils';

 import {url} from "../../config/config"


export const fetchHomelisting = createAsyncThunk('HomeList/fetchHome', async (payload) => {
    console.log(isLoggedIn("pgUser"),'CITY')
    const response = await axios.get(`${url}user/getHomeListing?type=${payload.type}&city=${payload.city}` ,payload,{
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;
});


const initialState = {
    homeData: {},
    status: 'idle',
    error: null,
}
const homeListingSlice = createSlice({
    name: 'homeListingData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomelisting.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchHomelisting.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.homeData = action.payload.data;
            })
            .addCase(fetchHomelisting.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default homeListingSlice.reducer;
