import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentLocation:""
};

const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    setCityState: (state, action) => {
      return { ...state, ...action.payload };
    }
  }
});

export const { setCityState } = citySlice.actions;
export default citySlice.reducer;